'use client';
import { useEffect } from 'react';
import Script from 'next/script';
import useUser from '@/components/useUser';

const FeaturebaseSurvey = () => {
  const user = useUser();

  useEffect(() => {

    const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
    const SURVEY_DELAY_MS = 2 * 60 * 1000;

    if (user?.isLoggedIn && user?.plans !== 'BASIC') {
      const currentTime = Date.now();
      const storedTime = localStorage.getItem('platformTimeSpent');
      
      let totalTimeSpent = storedTime ? parseInt(storedTime) : 0;
      const lastVisitTime = localStorage.getItem('lastVisitTime');
      
      if (lastVisitTime) {
        totalTimeSpent += currentTime - parseInt(lastVisitTime);
      }
      
      if (totalTimeSpent >= ONE_DAY_IN_MS) {
        // Update the time spent and last visit time before triggering the survey
        localStorage.setItem('platformTimeSpent', currentTime.toString());
        localStorage.setItem('lastVisitTime', currentTime.toString());

        setTimeout(() => {
          const win = window as any;

          if (typeof win.Featurebase !== 'function') {
            win.Featurebase = (...args: any[]) => {
              win.Featurebase.q = win.Featurebase.q || [];
              win.Featurebase.q.push(args);
            };
          } 

          win.Featurebase(
            'initialize_survey_widget',
            {
              organization: 'tokenmetrics',
              placement: 'bottom-left',
              theme: 'dark',
              email: user.userName
            },
            (err: Error) => {
              if (err) {
                console.error(err);
              } else {
                global.console.log('Survey triggered successfully!');
              }
            }
          );
        }, SURVEY_DELAY_MS);
      } else {
        // If the condition is not met, update the time spent and last visit time normally
        localStorage.setItem('platformTimeSpent', totalTimeSpent.toString());
        localStorage.setItem('lastVisitTime', currentTime.toString());
        
      }
    }
  }, [user]);

  return (
    <>
      <Script src="https://do.featurebase.app/js/sdk.js" id="featurebase-sdk" />
    </>
  );
};

export default FeaturebaseSurvey;
import { useEffect } from "react";
import Script from "next/script";

const FeaturebaseFeedbackWidget = () => {
  useEffect(() => {
    const win = window as any;

    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        win.Featurebase.q = win.Featurebase.q || [];
        win.Featurebase.q.push(arguments);
      };
    }
    win.Featurebase("initialize_feedback_widget", {
      organization: "tokenmetrics", 
      theme: "dark",
      placement: "bottom-left", 
      locale: "en", 
      metadata: null 
    });
  }, []);

  return (
    <>
      <Script src="https://do.featurebase.app/js/sdk.js" id="featurebase-sdk" />
    </>
  );
};

export default FeaturebaseFeedbackWidget;
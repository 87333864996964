"use client";
import Image from "next/image";
import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import Draggable from "react-draggable";

// importing ui
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";

// importing components
import HomeContainer from "@/components/chatbot/HomeContainer/HomeContainer";
import useUser from "@/components/useUser";

// importing multilanguage
import { useTranslation } from "@/components/translation/TranslationsProvider";

// importing image
import AskBot from "@/public/images/chatbot/tmai-agent-left.webp";

// importing icons
import { IoCloseOutline } from "react-icons/io5";

const ChatbotWidget = () => {
  const user = useUser();
  const { t } = useTranslation();
  const pathname = usePathname();
  const router = useRouter();
  const [openChatbotWidget, setOpenChatbotWidget] = useState<boolean>(false);

  return (
    <>
      {!pathname?.includes("tmai") && (
        <Draggable bounds="parent" handle=".chatbot-header">
          <div
            className={`fixed ${openChatbotWidget ? "bottom-[15px] right-[20px] sm:right-[20px]" : "bottom-[15px] right-[20px] sm:bottom-[15px] md:right-[20px]"} z-[2147483004]`}
          >
            {openChatbotWidget ? (
              <Card className="h-[858px] max-h-[90vh] w-[427px] max-w-[95vw]">
                <CardHeader className="chatbot-header flex h-[65px] cursor-move flex-row items-center justify-between px-[10px] sm:px-[15px]">
                  <div className="flex items-center">
                    <Image
                      src={AskBot}
                      alt="askbot"
                      className="mr-[10px] rounded-full border border-[#E6E9EF] dark:border-[#414249]"
                      width={40}
                      height={40}
                    />
                    <span>{t("market:aiChatbotAnswers")}</span>
                  </div>
                  <div className="flex items-center" style={{ marginTop: 0 }}>
                    {user?.isLoggedIn && (
                      <Button
                        onClick={() => {
                          router.push("/tmai");
                          setOpenChatbotWidget(false);
                        }}
                        data-testid="open-tmai-page"
                        className="mr-[12px] h-[34px] w-[34px] rounded-[4px] border border-[#E6E9EF] bg-[#F2F4FA] p-0 dark:border-[#414249] dark:bg-[#111116]"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.75 6.75L3 3M3 3V6M3 3H6M11.25 6.75L15 3M15 3V6M15 3H12M6.75 11.25L3 15M3 15V12M3 15H6M11.25 11.25L15 15M15 15V12M15 15H12"
                            stroke="#A1A1AA"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    )}
                    <button
                      type="button"
                      onClick={() => setOpenChatbotWidget(false)}
                      onPointerUp={() => setOpenChatbotWidget(false)}
                      data-testid="close-tmai-share-modal"
                      aria-label="Close"
                      role="button"
                    >
                      <IoCloseOutline
                        className="h-[24px] w-[24px] text-[#A1A1AA]"
                        data-testid="close-disclaimer-modal-button"
                      />
                    </button>
                  </div>
                </CardHeader>
                <CardContent
                  data-testid="-widget-chatContainer"
                  className="p-0"
                  style={{ height: "calc(100% - 65px)", userSelect: "text" }}
                >
                  <HomeContainer widgetContainer={true} />
                </CardContent>
              </Card>
            ) : (
              <Button
                className="group relative flex h-[50px] w-[50px] flex-col rounded-full p-0"
                onClick={() => setOpenChatbotWidget(true)}
                data-testid="open-tmai-widget"
              >
                <div className="absolute top-[-30px] hidden min-h-[25px] w-auto min-w-[87px] rounded-[4px] border border-[#E6E9EF] bg-[#F2F4FA] pt-[2px] group-hover:block dark:border-[#414249] dark:bg-[#111116]">
                  <p className="text-[12px] font-[400]">
                    {t("tmai:askChatbot")}
                  </p>
                </div>
                <Image
                  src={AskBot}
                  alt="askbot"
                  className="rounded-full border border-[#E6E9EF] dark:border-[#414249]"
                  width={50}
                  height={50}
                />
              </Button>
            )}
          </div>
        </Draggable>
      )}
    </>
  );
};

export default ChatbotWidget;

const componentIDs = {
  header: {
    homeButton: "header-home-button",
    tokenDropdownSearchInput: "header-token-dropdown-search-input",
    tokenDropdownCloseIcon: "header-token-dropdown-close-icon",
    tokenDropdownButton: "header-token-dropdown-button",
    themeToggle: "header-theme-toggle",
    notificationsDropdownButton: "header-notifications-dropdown-button",
    languageDropdownButton: "header-language-dropdown-button",
    userDropdownButton: "header-user-dropdown-button",
    signInButton: "header-sign-in-button",
    signUpButton: "header-sign-up-button",
    settingsButton: "header-settings-button",
    logoutButton: "header-logout-button",
    mobileSignInButton: "header-mobile-sign-in-button",
    mobileSignUpButton: "header-mobile-sign-up-button",
    openSidebarButton: "header-open-sidebar-button",
    closeSidebarButton: "header-close-sidebar-button",
    mobileHomeButton: "header-mobile-home-button",
  },
  signIn: {
    withGoogleButton: "sign-in-with-google-button",
    withNFTButton: "sign-in-with-nft-button",
    createAccountButton: "sign-in-create-account-button",
    emailInput: "sign-in-email-input",
    passwordInput: "sign-in-password-input",
    submitButton: "sign-in-submit-button",
    forgotPasswordButton: "sign-in-forgot-password-button",
    resendEmailButton: "sign-in-resend-email-button",
    closeEmailVerificationDialogButton:
      "sign-in-close-email-verification-dialog-button",
    showPasswordButton: "sign-in-show-password-button",
    hidePasswordButton: "sign-in-hide-password-button",
  },
  signUp: {
    withGoogleButton: "sign-up-with-google-button",
    firstNameInput: "sign-up-first-name-input",
    lastNameInput: "sign-up-last-name-input",
    emailInput: "sign-up-email-input",
    passwordInput: "sign-up-password-input",
    confirmPasswordInput: "sign-up-confirm-password-input",
    showPasswordButton: "sign-up-show-password-button",
    hidePasswordButton: "sign-up-hide-password-button",
    showConfirmPasswordButton: "sign-up-show-confirm-password-button",
    hideConfirmPasswordButton: "sign-up-hide-confirm-password-button",
    submitButton: "sign-up-submit-button",
    recaptcha: "sign-up-recaptcha",
    signInButton: "sign-up-sign-in-button",
  },
  forgotPassword: {
    emailInput: "forgot-password-email-input",
    submitButton: "forgot-password-submit-button",
    backToSignInButton: "forgot-password-back-to-sign-in-button",
  },
  resetPassword: {
    emailInput: "reset-password-email-input",
    passwordInput: "reset-password-password-input",
    confirmPasswordInput: "reset-password-confirm-password-input",
    showPasswordButton: "reset-password-show-password-button",
    hidePasswordButton: "reset-password-hide-password-button",
    showConfirmPasswordButton: "reset-password-show-confirm-password-button",
    hideConfirmPasswordButton: "reset-password-hide-confirm-password-button",
    submitButton: "reset-password-submit-button",
    backToSignInButton: "reset-password-back-to-sign-in-button",
  },
  plans: {
    yearlyMonthlyToggle: "plans-yearly-monthly-toggle",
    basicPlanStartButton: "plans-basic-start-button",
    basicPlanLearnMore: "plans-basic-learn-more",
    advancedPlanTrialButton: "plans-advanced-trial-button",
    advancedPlanLearnMore: "plans-advanced-learn-more",
    premiumPlanTrialButton: "plans-premium-trial-button",
    premiumPlanLearnMore: "plans-premium-learn-more",
    vipPlanApplyButton: "plans-vip-apply-button",
    vipPlanLearnMore: "plans-vip-learn-more",
  },
  payment: {
    couponInput: "payment-coupon-input",
    applyCouponButton: "payment-apply-coupon-button",
    removeCouponButton: "payment-remove-coupon-button",
    refundPolicyCheckbox: "payment-refund-policy-checkbox",
    refundPolicyLink: "payment-refund-policy-link",
    closeRefundPolicyButton: "payment-close-refund-policy-button",
    recaptcha: "payment-recaptcha",
    placeOrderButton: "payment-place-order-button",
  },
  upgrade: {
    upgradeHeader: "upgrade-header",
    couponInput: "upgrade-coupon-input",
    applyCouponButton: "upgrade-apply-coupon-button",
    yearlyMonthlyToggle: "upgrade-yearly-monthly-toggle",
    advancedPlanRadio: "upgrade-advanced-plan-radio",
    premiumPlanRadio: "upgrade-premium-plan-radio",
    startTrialButton: "upgrade-start-trial-button",
    comparePlansButton: "upgrade-compare-plans-button",
    closeButton: "upgrade-close-button",
    removeCouponButton: "upgrade-remove-coupon-button",
  },
  settings: {
    cancelPlanButton: "settings-cancel-Plan-button",
    managePlanButton: "settings-manage-Plan-button",
  },
} as const;

export default componentIDs;
